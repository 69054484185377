import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React, {
  MouseEvent, useCallback, useEffect, useRef,
} from 'react';
import useStores from 'stores/useStores';

export default function ConfirmationDialog() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cancelRef = useRef<any>();

  const { dialog, triggerDialog } = useStores((s) => ({
    dialog: s.dialog,
    triggerDialog: s.triggerDialog,
  }));
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (dialog) onOpen();
  }, [dialog]);

  const onClickLeftButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    dialog?.leftButtonHandler?.(e);
    onClose();
    triggerDialog(undefined);
  }, [dialog]);

  const onClickRightButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    dialog?.rightButtonHandler?.(e);
    onClose();
    triggerDialog(undefined);
  }, [dialog]);

  const onDismiss = useCallback(() => {
    onClose();
    triggerDialog(undefined);
  }, []);

  return dialog ? (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onDismiss}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{dialog.title ?? 'Are you sure?'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {dialog.description}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClickLeftButton}>
            {dialog.leftButtonLabel ?? 'No'}
          </Button>
          <Button colorScheme="red" ml={3} onClick={onClickRightButton}>
            {dialog.rightButtonLabel ?? 'Yes'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  ) : null;
}
