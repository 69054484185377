import { Link, navigate } from 'gatsby';
import React, { useCallback } from 'react';

import {
  Avatar, Box, Button, Grid, GridItem, HStack, Icon, IconButton,
  Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text,
} from '@chakra-ui/react';
import NotificationIcon from '@heroicons/react/24/outline/BellIcon';
import ChevronIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import SearchIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';

import useStores from 'stores/useStores';

export default function Navigation() {
  const { user, logout, triggerDialog } = useStores((s) => ({
    user: s.user,
    logout: s.logout,
    triggerDialog: s.triggerDialog,
  }));

  const onClickSignOut = useCallback(async () => {
    triggerDialog({
      title: 'Do you want to leave Green Cloud?',
      description: 'Your session will be restored on this device only',
      rightButtonHandler: async () => {
        await logout();
        navigate('/sign-in');
      },
    });
  }, []);

  return (
    <Box
      position="relative"
    >
      <Grid
        templateColumns="1fr 3fr 1fr"
        alignItems="center"
        py={2}
        _after={{
          height: '4px',
          position: 'absolute',
          top: '100%',
          right: 0,
          left: 0,
          background: 'linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px)',
          content: '""',
        }}
      >
        <GridItem />

        <GridItem>
          <HStack spacing={6}>
            <Link to="/">
              <Text>Dashboard</Text>
            </Link>
            <Link to="/teams">
              <Text>Team</Text>
            </Link>
            <Menu>
              <MenuButton size="sm" as={Button} rightIcon={<Icon as={ChevronIcon} />}>
                New
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link to="/domain/new">
                    <Text>Domain</Text>
                    <Text fontSize="sm" color="gray">Buy a new domain starting at $10</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/instance/new">
                    <Text>Server Instance</Text>
                    <Text fontSize="sm" color="gray">Spin up a new WordPress instance</Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </GridItem>

        <GridItem>
          <HStack spacing={1}>
            <IconButton aria-label="Search" variant="unstyled" icon={<Icon as={SearchIcon} />} />
            <IconButton
              aria-label="Notification"
              variant="unstyled"
              icon={(
                <Icon as={NotificationIcon} />
              )}
            />
            <Menu isLazy>
              <MenuButton><Avatar size="sm" name={user?.name} /></MenuButton>
              <MenuList>
                <MenuItem>
                  <Link to="/profile">Profile</Link>
                </MenuItem>
                <MenuItem onClick={onClickSignOut}>Sign Out</MenuItem>
                <MenuDivider />
                <MenuItem>Docs</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  );
}
