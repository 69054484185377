import { handleGraphResponse, makeGraphRequest } from 'utils/common';
import { GRAPHQL_HOST } from 'utils/constants/urls';
import { MUTATE_USER, QUERY_USER } from 'utils/queries';

export const getUser = async (key: 'email' | 'userId', value: string) => {
  const response = await fetch(
    makeGraphRequest({
      url: GRAPHQL_HOST,
      query: QUERY_USER.toString(),
      variables: {
        where: {
          [key]: value,
        },
      },
    }),
  );

  return handleGraphResponse(response);
};

export const updateUser = async (userId: string, data: Record<string, string | undefined>) => {
  const response = await fetch(
    makeGraphRequest({
      url: GRAPHQL_HOST,
      query: MUTATE_USER.toString(),
      variables: {
        set: data,
        where: {
          userId,
        },
      },
    }),
  );

  return handleGraphResponse(response);
};
