/* eslint-disable max-classes-per-file */
interface ApiErrorProps extends Error {
  code: number;
}

export class ApiError extends Error implements ApiErrorProps {
  public code: number;

  constructor(code: number, causedBy?: string, message?: string) {
    super(message, {
      cause: causedBy?.toUpperCase(),
    });
    this.code = code;
  }
}
