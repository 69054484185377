import {
  Flex, Icon, Stack, Text,
} from '@chakra-ui/react';
import AcademicIcon from '@heroicons/react/24/outline/AcademicCapIcon';
import { Link } from '@reach/router';
import { PageProps } from 'gatsby';
import React from 'react';
import useStores from 'stores/useStores';

const navigations = [
  {
    label: 'Domain',
    href: '/domains',
  },
  {
    label: 'Instance',
    href: '/instances',
  },
];

export default function LeftNavigation({ pageProps }: { pageProps: PageProps }) {
  const user = useStores((s) => s.user);

  return (
    <Stack background="#F1F2F5" height="calc(100vh - 48px)" direction="column">
      <Flex gap={2} p={6}>
        <Icon as={AcademicIcon} mt={1} boxSize={10} p={1} background="primary" color="white" rounded={4} />
        <div>
          <Text fontWeight="bold">{user?.companyName ?? 'Company Name'}</Text>
          <Text fontSize="sm" color="gray">Customer</Text>
        </div>
      </Flex>

      <Text px={2} fontSize="smaller" textTransform="uppercase" fontWeight="extrabold">Resources</Text>
      {navigations.map((nav) => (
        <Link key={nav.href} to={nav.href} style={{ marginTop: 0 }}>
          <Text
            p={2}
            _hover={{ background: '#eeeeee' }}
            background={pageProps.path === nav.href ? '#eeeeee' : undefined}
          >
            {nav.label}
          </Text>
        </Link>
      ))}
    </Stack>
  );
}
