import { Box, Grid, GridItem } from '@chakra-ui/react';
import { navigate, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import LeftNavigation from 'components/leftNavigation';
import Navigation from 'components/navigation';
import useStores from 'stores/useStores';
import ConfirmationDialog from 'components/confirmationDialog';
import ErrorHandler from './errorHandler';
import SessionProvider from './sessionProvider';

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  pageProps: PageProps;
}

export default function Layout({ children, pageProps }: LayoutProps) {
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = useStores((s) => s.accessToken);

  useEffect(() => {
    if (pageProps.path === '/' && !accessToken) {
      navigate('/sign-in');
      return;
    }

    setIsLoading(false);
  }, [accessToken, pageProps]);

  if (isLoading) return null;

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <SessionProvider>
        <Box>
          <Navigation />

          <Grid templateColumns="1fr 3fr 1fr">
            <GridItem><LeftNavigation pageProps={pageProps} /></GridItem>
            <GridItem colSpan={2} p={4}>{children}</GridItem>
          </Grid>
        </Box>

        <ConfirmationDialog />
      </SessionProvider>
    </ErrorBoundary>
  );
}
