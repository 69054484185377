import type { StateCreator } from 'zustand';
import jwt from 'jwt-decode';

import { ApiResponse } from 'interfaces/api.interface';
import { makeRequest } from 'utils/common';
import { ApiError } from 'utils/errors';
import { AUTH_URL_REFRESH_TOKEN, AUTH_URL_SIGN_IN, AUTH_URL_SIGN_OUT } from 'utils/constants/urls';
import { StoreStates } from './useStores';

interface AccessTokenProps {
  authorizationToken: string;
  accessToken: string;
  refreshToken: string;
  decodedToken: {
    username: string;
    exp: number;
    clientId: string;
  };
}

interface JwtDecode {
  username: string;
  // eslint-disable-next-line camelcase
  client_id: string;
  exp: number;
}

export interface AuthState extends Partial<AccessTokenProps> {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshAccessToken: () => Promise<void>;
  cleanupToken: () => void;
}

const authStore: StateCreator<StoreStates, [['zustand/devtools', never], ['zustand/persist', unknown]], [], AuthState> = (set, get) => ({
  login: async (username: string, password: string) => {
    const response = await fetch(
      makeRequest({
        url: AUTH_URL_SIGN_IN,
        method: 'post',
        body: JSON.stringify({ username, password }),
      }),
    );
    if (!response.ok) throw new ApiError(500, 'request_failed', 'Request failed.');

    const data = await response.json() as ApiResponse<AccessTokenProps>;
    if (data.error) throw new ApiError(412, 'auth_incorrect_credentials', 'Username or password is incorrect.');

    const decodedToken = jwt(data.accessToken) as JwtDecode;

    set(() => ({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      authorizationToken: data.authorizationToken,
      decodedToken: {
        username: decodedToken.username,
        clientId: decodedToken.client_id,
        exp: decodedToken.exp,
      },
    }));
  },
  logout: async () => {
    const response = await fetch(
      makeRequest({
        url: AUTH_URL_SIGN_OUT,
        method: 'post',
        token: {
          authorization: get().authorizationToken,
          accessToken: get().accessToken,
        },
      }),
    );
    if (!response.ok) throw new ApiError(500, 'request_failed', 'Cannot sign you out. Please try it again.');
    if (response.status === 403) throw new ApiError(401, 'unauthorized', 'Authorization is required. Please sign in again.');

    const data = await response.json() as ApiResponse<string>;
    if (data.error) throw new ApiError(412, 'auth_failed', 'Cannot sign you out. Please try it again.');

    set(() => ({
      accessToken: undefined,
      refreshToken: undefined,
      authorizationToken: undefined,
      errorMessage: undefined,
    }));
  },
  cleanupToken: () => {
    set(() => ({
      accessToken: undefined,
      refreshToken: undefined,
      authorizationToken: undefined,
    }));
  },
  refreshAccessToken: async () => {
    const response = await fetch(
      makeRequest({
        url: AUTH_URL_REFRESH_TOKEN,
        method: 'post',
        token: {
          authorization: get().authorizationToken,
          accessToken: get().accessToken,
        },
        body: JSON.stringify({
          refreshToken: get().refreshToken,
        }),
      }),
    );
    if (!response.ok) throw new ApiError(500, 'request_failed', 'Failed to get refresh token. Please try again.');
    if (response.status === 403) throw new ApiError(401, 'unauthorized', 'Authorization is required. Please sign in again.');

    const data = await response.json() as ApiResponse<AccessTokenProps & { Message: string; }>;
    if (data.Message) throw new ApiError(412, 'invalid_parameter', data.Message);

    set(() => ({
      accessToken: data.accessToken,
      authorizationToken: data.authorizationToken,
      errorMessage: undefined,
    }));
  },
});

export default authStore;
