import React from 'react';
import Layout from './src/components/layout';

export const wrapPageElement = ({ element, props }) => {
  if (props.path.includes('/sign-in')) return element;
  if (props.pageResources?.page?.path.includes(404)) return element;
  if (props.pageResources?.page?.path.includes(500)) return element;

  return <Layout pageProps={props}>{element}</Layout>;
};
