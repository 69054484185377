import { navigate } from '@reach/router';
import React, { useCallback, useEffect, useRef } from 'react';
import useStores from 'stores/useStores';
import { ApiError } from 'utils/errors';

export default function SessionProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const refreshTokenIntervalRef = useRef();

  const {
    fetchUser, decodedToken, refreshAccessToken, cleanupToken,
  } = useStores((s) => ({
    fetchUser: s.fetchUser,
    decodedToken: s.decodedToken,
    refreshAccessToken: s.refreshAccessToken,
    cleanupToken: s.cleanupToken,
  }));

  const getToken = useCallback(async () => {
    try {
      await refreshAccessToken();
    } catch (err: unknown) {
      cleanupToken();
      navigate('/sign-in');
    }
  }, []);

  const fetchDataStore = useCallback(async () => {
    try {
      await fetchUser(decodedToken?.username ?? '');
    } catch (err: unknown) {
      if (err instanceof ApiError) {
        if (err.cause === 'UNAUTHORIZED') {
          cleanupToken();
          navigate('/sign-in');
        }
      }
    }
  }, [decodedToken?.username]);

  useEffect(() => {
    const interval = setInterval(() => getToken(), 5400000);
    refreshTokenIntervalRef.current = interval as unknown as undefined;

    fetchDataStore();

    return () => clearInterval(interval);
  }, [getToken, fetchDataStore]);

  return (
    <div>
      {children}
    </div>
  );
}
