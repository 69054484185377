export const QUERY_USER = `
  query UserDetail(
    $where: UserWhereClause!
  ) {
      userDetail(
        where: $where
      ) {
        userId,
        email,
        firstName,
        lastName,
        name,
        birthDate,
        phone,
        address,
        companyName,
        createdAt,
        updatedAt
      }
  }
`;

export const MUTATE_USER = `
  mutation UserUpdate(
    $where: UserWhereClause!
    $set: UserUpdate!
  ) {
    updateUserDefinition(
        set: $set
        where: $where
    ) {
        firstName,
        lastName,
        birthDate,
        phone,
        address,
        companyName
    }
  }
`;
