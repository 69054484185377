import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { AuthState } from './authStore';
import type { AppState } from './appStore';

import authStore from './authStore';
import appStore from './appStore';

export type StoreStates = AuthState & AppState;

const useStores = create<StoreStates>()(
  devtools(
    persist(
      (...a) => ({
        ...authStore(...a),
        ...appStore(...a),
      }),
      {
        name: 'gc',
      },
    ),
  ),
);

// See: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern
export default useStores;
