import React from 'react';

interface ErrorHandlerProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export default function ErrorHandler({ error, resetErrorBoundary }: ErrorHandlerProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="submit" onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
