import { extendTheme } from '@chakra-ui/react';

const theme = {
  colors: {
    primary: '#0B5FFF',
    primaryHover: '#4283FF',
  },
};

export default extendTheme(theme);
