import { MouseEventHandler } from 'react';
import { getUser } from 'utils/requests/graphql';
import { StateCreator } from 'zustand';
import { StoreStates } from './useStores';

interface UserState {
  user?: {
    userId: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    birthDate?: string;
    phone?: string;
    address?: string;
    companyName?: string;
    createdAt: number;
    updatedAt: number;
  };
  fetchUser: (userId: string) => Promise<void>;
}

interface ConfirmationDialogState {
  dialog?: {
    title?: string;
    description: string;
    leftButtonLabel?: string;
    rightButtonLabel?: string;
    leftButtonHandler?: MouseEventHandler<HTMLButtonElement>;
    rightButtonHandler: MouseEventHandler<HTMLButtonElement>;
  }
  triggerDialog: (opt: ConfirmationDialogState['dialog']) => void;
}

export type AppState = UserState & ConfirmationDialogState;

const appStore: StateCreator<StoreStates, [['zustand/devtools', never], ['zustand/persist', unknown]], [], AppState> = (set) => ({
  user: undefined,
  fetchUser: async (userId: string) => {
    const result = await getUser('userId', userId);

    set({ user: result?.data?.userDetail });
  },

  dialog: undefined,
  triggerDialog: (opt: ConfirmationDialogState['dialog']) => set({
    dialog: opt,
  }),
});

export default appStore;
