/* eslint-disable max-len */
import useStores from 'stores/useStores';
import { ApiError } from './errors';

interface MakeRequestInput {
  url: string;
  method?: string;
  body?: string;
  token?: {
    authorization?: string;
    accessToken?: string;
  }
}

export type GraphVariable = string | number | Date | Record<string, string> | Record<string, string | undefined> | boolean | undefined;

interface MakeGraphRequestInput {
  url: string;
  query?: string;
  variables?: Record<string, GraphVariable>;
}

export const makeRequest = ({
  url, method, body, token,
}: MakeRequestInput) => {
  const newHeaders = new Headers({
    'Content-Type': 'application/json',
    Origin: 'urgreencloud.com',
    ...(token ? {
      Authorization: token?.authorization,
      AccessToken: token?.accessToken,
    } : undefined),
  } as Record<string, string>);
  const newRequest = new Request(url, {
    body,
    method: method ?? 'get',
    headers: newHeaders,
    cache: 'no-cache',
  });

  return newRequest;
};

export const makeGraphRequest = ({
  url, query, variables,
}: MakeGraphRequestInput) => {
  const state = useStores.getState();
  return makeRequest({
    url,
    method: 'POST',
    body: JSON.stringify({
      query,
      variables,
    }),
    token: {
      accessToken: state.accessToken,
      authorization: state.authorizationToken,
    },
  });
};

export const handleGraphResponse = async (response: Response) => {
  if (response.status === 401) throw new ApiError(401, 'unauthorized', 'Authorization is required. Please sign in again.');
  if (!response.ok) throw new ApiError(500, 'graphql', 'Authorization is required. Please sign in again.');

  const result = await response.json();

  return {
    data: result.data,
    errors: result.errors?.map((e: Record<string, string>) => `${e.errorType}: ${e.message}`),
  };
};

export const debounce = (timeout = 750) => {
  let timer: NodeJS.Timer;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (func: any, ...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
};
